import jQuery from 'jquery';
var $ = window.jQuery = jQuery;
import 'popper.js';
import 'bootstrap';
import Slider from 'bootstrap-slider';
import {HeliosNavigation, HeliosSearch, HeliosLanguage, HeliosLogin} from '_helios.navigation.mjs'

// vanilla js
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

document.addEventListener("DOMContentLoaded", function () {
	console.info('%cWebsite made with \u2665 by Stereobrand','font-size:11px;font-weight:bold');
	console.info('%chttps://www.stereobrand.de','font-size:11px');

	// init helios modules
	new HeliosSearch();
	new HeliosLanguage();
	new HeliosLogin();

	// make radio buttons de-selectable
	document.querySelectorAll('input[type=radio]')
		.forEach((item) => {
			item.dataset.value = item.checked;
			item.addEventListener('click', () => {
				if (item.dataset.value == 'true') {
					item.checked = false;
					item.dataset.value = false;
					return false;
				} else {
					item.dataset.value = true;
				}
			});
		});

	// step 1 next click events
	document.querySelectorAll('#use-tab-pane .step-1 a[data-target]')
		.forEach((link) => {
			link.addEventListener('click', () => {
				document.querySelector('#use-tab-pane .step-1').classList.remove('active');
				document.querySelector('#use-tab-pane .step-2').classList.add('active');
				document.querySelectorAll('#use-tab-pane .step-2 .zone')
					.forEach((zone) => {
						if (!zone.classList.contains(link.dataset.target)) {
							zone.classList.add('d-none');
						} else {
							zone.classList.remove('d-none');
						}
					});
			})
		});

	// step 2 next click events
	document.querySelectorAll('#use-tab-pane .step-2 a[data-target]')
		.forEach((link) => {
			link.addEventListener('click', () => {
				document.querySelector('#use-tab-pane .step-2').classList.remove('active');
				document.querySelector('#use-tab-pane .step-3').classList.add('active');
				document.querySelectorAll('#use-tab-pane .step-3 .solution')
					.forEach((solution) => {
						if (!solution.classList.contains(link.dataset.target)) {
							solution.classList.add('d-none');
						} else {
							solution.classList.remove('d-none');
						}
					});
			})
		});

	// step back click events
	document.querySelectorAll('a[data-reset-step]')
		.forEach((item) => {
			item.addEventListener('click', () => {
				if (item.dataset.resetStep == 1) {
					document.querySelector('#use-tab-pane .step-1').classList.add('active');
					document.querySelector('#use-tab-pane .step-2').classList.remove('active');
					document.querySelector('#use-tab-pane .step-3').classList.remove('active');
				}
				if (item.dataset.resetStep == 2) {
					document.querySelector('#use-tab-pane .step-1').classList.remove('active');
					document.querySelector('#use-tab-pane .step-2').classList.add('active');
					document.querySelector('#use-tab-pane .step-3').classList.remove('active');
				}
			})
		});

	// show autosuggest search results when focusing search input
	document.querySelectorAll('.autosuggest__input')
		.forEach((item) => {
			item.addEventListener('focus', () => {
				item.closest('.autosuggest').querySelector('.autosuggest__results').classList.remove('d-none');
			})
			item.addEventListener('blur', () => {
				item.closest('.autosuggest').querySelector('.autosuggest__results').classList.add('d-none');
			})
		});

});

// jquery based js
(function($) {

	// check required elements to activate/deactivate submit btn in tab 1
	function checkFields() {
		let formValid = true;
		// test if all required fields are empty and category count is 0
		$('#category-tab-pane input[required]').each(function() {
			if (!this.checkValidity()) formValid = false;
		})
		if ($('#category-tab-pane .result').data('global-count') == 0) formValid = false;
		if (formValid) {
			$('#category-tab-pane [type=submit]').prop('disabled', false);
		} else {
			$('#category-tab-pane [type=submit]').prop('disabled', 'disabled');
		}
	}

	$(function() {
		// disable submit btn in tab 1 (necessary bc form is included)
		$('#category-tab-pane [type=submit]').prop('disabled', 'disabled');

		// get result label based on json on page load
		if (sessionStorage.getItem('heliosCategories') != null) {
			let resultCategories = JSON.parse(sessionStorage.getItem('heliosCategories')).filter(cat => {
				return cat.checked === true;
			});
			let resultLabel = 'Noch keine Kategorien ausgewählt';
			if (resultCategories.length){
				resultLabel = resultCategories[0].name;
				if (resultCategories.length > 1) {
					resultLabel += ' + '+(resultCategories.length-1)+' weitere';
				}
			}
			$('#category-tab-pane .result').text(resultLabel);
			$('#category-tab-pane .result').data('global-count', resultCategories.length);
			checkFields();
		}

		// reset tab content on tab click
		$('[data-toggle="tab"]').on('shown.bs.tab', function (event) {
			// trigger form resets in step 1 and 3
			$('#category-tab-pane form, #search-tab-pane form').trigger('reset');

			// reset to step 1 in tab 2
			$('#use-tab-pane .step-1').addClass('active');
			$('#use-tab-pane .step-2').removeClass('active');
			$('#use-tab-pane .step-2 .card').removeClass('active');
			$('#use-tab-pane .step-2 .card .collapse').collapse('hide');
			$('#use-tab-pane .step-3').removeClass('active');
		});

		// change event on required elements to activate/deactivate submit btn in tab 1
		$('#category-tab-pane input[required]').on('input', checkFields);

		// global variable for categories state
		var heliosCategories = [];

		// assign content to form modals
		$('#formModal, #basketModal, #categoryModal').on('show.bs.modal', function (event) {
		  let button = $(event.relatedTarget);
		  let content = button.data('content');
			let modal = $(this);
			$.get('/templates/'+content+'.html', function(data) {
				modal.find('.modal-body').empty().append(data);
				modal.find('form.needs-validation').addCustomValidation();
				modal.find('[data-toggle="popover"]').popover({trigger: 'focus'});
				modal.find('[data-toggle="tooltip"]').tooltip();

				// categroy modal functions
				modal.find('.select-all').on('click', function() {
					$(this).parents('.form-row').find('[type="checkbox"]').each(function() {
						if (!$(this).prop('checked'))
							$(this).prop('checked', true).change();
					});
				});
				modal.find('.deselect-all').on('click', function() {
					$(this).parents('.form-row').find('[type="checkbox"]').each(function() {
						if ($(this).prop('checked'))
							$(this).prop('checked', false).change();
					});
				});
				modal.find('.global-select-all').on('click', function() {
					$(this).parents('#categoryModal').find('#type-filter [type="checkbox"]').each(function() {
						if (!$(this).prop('checked'))
							$(this).prop('checked', true).change();
					});
				});
				modal.find('.global-deselect-all').on('click', function() {
					$(this).parents('#categoryModal').find('#type-filter [type="checkbox"]').each(function() {
						if ($(this).prop('checked'))
							$(this).prop('checked', false).change();
					});
				});
				modal.find('.global-collapse-show-all').on('click', function() {
					$(this).parents('#categoryModal').find('#type-filter [data-toggle="collapse"]').attr('aria-expanded', 'true').removeClass('collapsed');
					$(this).parents('#categoryModal').find('#type-filter .collapse').addClass('show');
				});
				modal.find('.global-collapse-hide-all').on('click', function() {
					$(this).parents('#categoryModal').find('#type-filter [data-toggle="collapse"]').attr('aria-expanded', 'false').addClass('collapsed');
					$(this).parents('#categoryModal').find('#type-filter .collapse').removeClass('show');
				});
				modal.find('.image-checkbox input').on('change', function() {
					let counterElement = $(this).parents('.form-row').find('.counter');
					let globalCounterElement = modal.find('.global-select-all');
					let selectAll = counterElement.find('.select-all');
					let deselectAll = counterElement.find('.deselect-all');
					let counterChecked = counterElement.data('checked');
					let counterTotal = counterElement.data('total');
					let counterGlobal = globalCounterElement.data('global-count');
					let resultLabel = 'Noch keine Kategorien ausgewählt';
					let resultElement = $('#category-tab-pane .result');

					if (this.checked) {
						// now checked
						if (counterChecked < counterTotal) {
							counterChecked++;
							counterGlobal++;
						}
					} else {
						// now unchecked
						if (counterChecked > 0) {
							counterChecked--;
							counterGlobal--;
						}
					}
					// change label styles
					if (counterChecked == 0) {
						// nothing checked
						selectAll.removeClass('font-weight-bold');
						deselectAll.addClass('disabled');
					} else if (counterChecked < counterTotal) {
						// some checked
						selectAll.removeClass('font-weight-bold');
						deselectAll.removeClass('disabled');
					} else {
						// all checked
						selectAll.addClass('font-weight-bold');
						deselectAll.removeClass('disabled');
					}

					// update json in session storage
					heliosCategories.forEach((item) => {
						if (item.id == this.id) {
							item.checked = this.checked;
						}
					});
					sessionStorage.setItem('heliosCategories', JSON.stringify(heliosCategories));

					// change form
					if (counterGlobal > 0 ) {
						// todo add label w/ chosen categories (get first checked option)
						let resultCategories = heliosCategories.filter(cat => {
							return cat.checked === true;
						});
						//resultLabel = modal.find('.image-checkbox input:checked').first().parent('.image-checkbox').find('label').data('original-title')
						resultLabel = resultCategories[0].name;
						if (counterGlobal > 1) {
							//resultLabel += ' + '+(counterGlobal-1)+' weitere';
							resultLabel += ' + '+(resultCategories.length-1)+' weitere';
						}
					}

					// display result label in tab
					resultElement.text(resultLabel);
					resultElement.data('global-count', counterGlobal);

					// save count in element
					globalCounterElement.data('global-count', counterGlobal);
					counterElement.data('checked', counterChecked);

					checkFields();
				});

				if ($(event.target).attr('id') == 'categoryModal') {
					// load json from session storage
					heliosCategories = JSON.parse(sessionStorage.getItem('heliosCategories'));
					if (heliosCategories != null) {
						// reset global counter
						let globalCounterElement = modal.find('.global-select-all');
						globalCounterElement.data('global-count', 0);

						// init checkboxes
						heliosCategories.forEach((item) => {
							if (item.checked != $('#'+item.id).prop('checked')) {
								$('#'+item.id).prop('checked', item.checked).change();
							}
						});
					} else {
						heliosCategories = [];
						modal.find('.image-checkbox input').each(function() {
							let item = {id: $(this).attr('id'), name: $(this).parent('.image-checkbox').find('label').data('original-title'), checked: $(this).prop('checked')}
							heliosCategories.push(item);
							sessionStorage.setItem('heliosCategories', JSON.stringify(heliosCategories));
						});
					}

				}

			});
		})
		// assign content to account modal
		$('#accountModal').on('show.bs.modal', function (event) {
		  let button = $(event.relatedTarget);
		  let content = button.data('content');
			let modal = $(this);
			let url = 'https://account.helios.stereobrand.com'+content;
			modal.find('iframe').attr("src", url);
		});
		// reset src on modal close
		$('#accountModal').on('hidden.bs.modal', function (event) {
			let modal = $(this);
			modal.find('iframe').attr("src", '');
		});

		// apply custom validation to forms
		$('form.needs-validation').addCustomValidation();
		// init tooltips
		$('[data-toggle="tooltip"]').tooltip();

		// format tolerance slider output
		$('input.tolerance').slider({
			formatter: function(val) {
				let prefix = '+';
				let suffix = ' %';
				if (Array.isArray(val)) {
					val.forEach(function(e, i){
						val[i] = (e > 0) ? prefix+e+suffix : e+suffix;
					})
					return val[0] + " : " + val[1];
				} else {
					return (val > 0) ? prefix+val+suffix : val+suffix;
				}
			}
		});
		// refresh with current values to display percentage via formatter
		$('input.tolerance').slider('refresh', { useCurrentValue: true });

		$('form.needs-validation').on('reset', function(){
			// reset sliders
			$('input.tolerance').slider('refresh');
			// reset category selection
			$('#category-tab-pane .result').text('Noch keine Kategorien ausgewählt');
			$('#category-tab-pane .result').data('global-count', 0);
			sessionStorage.removeItem('heliosCategories');
			$('#category-tab-pane [type=submit]').prop('disabled', 'disabled');
		});

		// make btn active when opening collapse in step 2
		$('.step-2 .collapse').on('show.bs.collapse', function (e) {
			$(this).parents('li').find('.card').addClass('active');
		})
		$('.step-2 .collapse').on('hide.bs.collapse', function (e) {
			$(this).parents('li').find('.card').removeClass('active');
		})

	});

	$.fn.addCustomValidation = function() {
		this.on('submit', function(e){
			if (!this.checkValidity()) {
				e.preventDefault();
				e.stopPropagation();
			}
			$(this).addClass('was-validated');
		});
	}

}(jQuery));
