var bodyState = ['myhelios', 'search', 'language'];
var menuState = [];
menuState['myhelios'] = false;
menuState['search'] = false;
menuState['language'] = false;

function setBodyState(target) {
	if (document.body.classList.contains(target+'-open')) {
		document.body.classList.remove(target+'-open');
		menuState[target] = false;
	} else {
		bodyState.forEach(function(el) {
			menuState[el] = false;
			document.body.classList.remove(el+'-open');
			// change icon
			let icon = document.querySelector('header .navbar .usermenu .'+el+' svg use');
			if (el == 'myhelios') {
				icon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#global.nav.account');
			} else if (el == 'language') {
				let toggle = document.querySelector('header .navbar .usermenu .'+el);
				// get country from element storage
				if (toggle.dataset.country != undefined) {
					icon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#country.'+toggle.dataset.country);
				} else {
					icon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#global.nav.'+el);
				}
			} else {
				icon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#global.nav.'+el);
			}
		});
		document.body.classList.add(target+'-open');
		menuState[target] = true;
	}
}

export var HeliosSearch = function() {
	this.options = [];
	this.menuOpen = false;
	this.status = 'search';
	this.options.menu = document.querySelector('#dropdown-search');
	this.options.toggle = document.querySelector('header .navbar .usermenu .search');
	this.options.toggleIcon = this.options.toggle.querySelector('svg use');
	this.options.iconMenu = 'global.nav.search';
	this.options.iconClose = 'global.nav.close';
	this.init();
}

HeliosSearch.prototype.init = function() {
	this.toggleButtonEvent();
}

HeliosSearch.prototype.setBodyState = function() {
	setBodyState.call(this, this.status);
}

HeliosSearch.prototype.toggleButtonEvent = function() {
	this.options.toggle.addEventListener('click', function (event) {
		this.setBodyState();
		this.options.toggleIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#'+(menuState[this.status]?this.options.iconClose:this.options.iconMenu));
	}.bind(this));
}

// todo add events for language switching
export var HeliosLanguage = function() {
	this.options = [];
	this.menuOpen = false;
	this.status = 'language';
	this.options.menu = document.querySelector('#dropdown-language');
	this.options.toggle = document.querySelector('header .navbar .usermenu .language');
	this.options.toggleIcon = this.options.toggle.querySelector('svg use');
	this.options.iconMenu = 'global.nav.language';
	this.options.iconClose = 'global.nav.close';
	this.init();
}

HeliosLanguage.prototype.init = function() {
	this.toggleButtonEvent();
	this.toggleSwitch();
}

HeliosLanguage.prototype.setBodyState = function() {
	setBodyState.call(this, this.status);
}

HeliosLanguage.prototype.toggleButtonEvent = function() {
	this.options.toggle.addEventListener('click', function (event) {
		this.setBodyState();
		if (this.options.toggle.dataset.country != undefined) {
			if (menuState[this.status]) {
				this.options.toggleIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#'+this.options.iconClose);
			} else {
				this.options.toggleIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#country.'+this.options.toggle.dataset.country);
			}
		} else {
			this.options.toggleIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#'+(menuState[this.status]?this.options.iconClose:this.options.iconMenu));
		}
	}.bind(this));
}

HeliosLanguage.prototype.switchFlag = function(country, language) {
	let icon = this.options.toggle.querySelector('.icon use');
	let badge = this.options.toggle.querySelector('.badge');
	icon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#country.'+country);
	badge.innerHTML = language.toUpperCase();
}

HeliosLanguage.prototype.switchLanguage = function(country, language) {
	// store choice in element
	this.options.toggle.dataset.country = country;
	this.options.toggle.dataset.language = language;
	// change the language
	console.info('Hier sollte der Code stehen, der eine Sprachwahl zu '+language+'-'+country+' veranlasst.')
	// if page is not going to be reloaded, close the menu
	this.options.toggle.click();
}

HeliosLanguage.prototype.toggleSwitch = function() {
	// add events to language menu / desktop
	this.options.menu.querySelectorAll('a[data-language]').forEach(function(item) {
		item.addEventListener('click', function(e) {
			this.switchFlag(item.dataset.country, item.dataset.language);
			this.switchLanguage(item.dataset.country, item.dataset.language);
		}.bind(this));
	}.bind(this));

	// add events to language menu / mobile
	this.options.menu.querySelector('form.language').addEventListener('submit', function(e) {
		e.preventDefault();
		let country = e.target.querySelector('[name=country]').value;
		let language = e.target.querySelector('[name=language]').value;
		this.switchFlag(country, language);
		this.switchLanguage(country, language);
	}.bind(this));
}

export var HeliosLogin = function() {
	this.options = [];
	this.menuOpen = false;
	this.status = 'myhelios';
	this.options.menu = document.querySelector('#dropdown-myhelios');
	this.options.toggle = document.querySelector('header .navbar .usermenu .myhelios');
	this.options.toggleIcon = this.options.toggle.querySelector('svg use');
	this.options.iconMenu = 'global.nav.account';
	this.options.iconClose = 'global.nav.close';
	this.options.loggedInView = this.options.menu.querySelector('.loggedin');
	this.options.loggedOutView = this.options.menu.querySelector('.loggedout');
	this.options.logInBtn = this.options.loggedOutView.querySelector('.login');
	this.options.logOutBtn = this.options.loggedInView.querySelector('.logout');
	this.options.pwdResetBtn = this.options.loggedOutView.querySelector('.pwdreset');
	this.options.backBtn = this.options.loggedOutView.querySelector('.back');
	this.init();
}

HeliosLogin.prototype.init = function() {
	this.toggleButtonEvent();
}

HeliosLogin.prototype.setBodyState = function() {
	setBodyState.call(this, this.status);
}

HeliosLogin.prototype.doLogIn = function() {
	this.options.loggedOutView.classList.add('d-none');
	this.options.loggedInView.classList.remove('d-none');
}

HeliosLogin.prototype.doLogOut = function() {
	this.options.loggedInView.classList.add('d-none');
	this.options.loggedOutView.classList.remove('d-none');
}

HeliosLogin.prototype.showPwdReset = function() {
	(this.options.loggedOutView.querySelector('.login-view')).classList.add('d-none');
	(this.options.loggedOutView.querySelector('.pwdreset-view')).classList.remove('d-none');
}

HeliosLogin.prototype.hidePwdReset = function() {
	(this.options.loggedOutView.querySelector('.pwdreset-view')).classList.add('d-none');
	(this.options.loggedOutView.querySelector('.login-view')).classList.remove('d-none');
}

HeliosLogin.prototype.toggleButtonEvent = function() {
	this.options.toggle.addEventListener('click', function (event) {
		this.setBodyState();
		this.options.toggleIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/assets/img/icon-sprite.svg#'+(menuState[this.status]?this.options.iconClose:this.options.iconMenu));
	}.bind(this));

	this.options.logInBtn.addEventListener('click', function(event){
		event.preventDefault();
		event.stopPropagation();
		this.doLogIn();
	}.bind(this));

	this.options.logOutBtn.addEventListener('click', function(event){
		event.preventDefault();
		event.stopPropagation();
		this.doLogOut();
	}.bind(this));

	this.options.pwdResetBtn.addEventListener('click', function(event){
		event.preventDefault();
		event.stopPropagation();
		this.showPwdReset();
	}.bind(this));

	this.options.backBtn.addEventListener('click', function(event){
		event.preventDefault();
		event.stopPropagation();
		this.hidePwdReset();
	}.bind(this));
}
